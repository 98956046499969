import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout"
import EbookHero from "../components/ebook-hero"
import SEO from "../components/seo"
import Container from "../components/container"
import CalendarImg from "../images/ebooks/icons/calendar.svg"
import MindImg from "../images/ebooks/icons/mind.svg"
import TargetImg from "../images/ebooks/icons/target.svg"
import CoffeeImg from "../images/ebooks/icons/coffee.svg"
import PersonHoldinTabletImg from "../images/ebooks/person-holding-tablet.png"
import theme from "../consts/theme"
import { getEbooks } from "../utils"

const SC = {}

const EbookEstudosMaisProdutivos = () => {
  const ebook = getEbooks("Como tornar seus estudos mais produtivos")

  return (
    <Layout>
      <SEO
        title={`[eBook] ${ebook.title}`}
        description={ebook.description}
        canonical={ebook.url.substring(1)}
        author="Apetrecho Digital"
      />
      <EbookHero
        cover={ebook.cover}
        title={`Torne seus estudos
          <br />
          <span>+ produtivos</span> sem
          <br />
          precisar estudar mais`}
        description={`Aprenda a <b>melhor forma</b> de tornar seus
          <br />
          estudos mais produtivos, aumentando a<br />
          qualidade e <b>diminuindo a quantidade</b>.`}
        titlePrimaryColor="#fff"
        titleSecondaryColor={theme.colors.PRIMARY_GREEN}
        descriptionColor="#fff"
        leftBgColor={theme.colors.SECONDARY_PURPLE}
        rightBgColor="#fff"
        ebookTag={ebook.mailchimpTag}
        inputBgColor="#FFFFFF22"
      />
      <SC.Content
        isFlex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <SC.ContentTitle>Neste eBook você vai conferir:</SC.ContentTitle>
        <SC.ContentItems>
          <SC.ContentItem>
            <img src={CalendarImg} alt="Calendário" />
            <span>a importância da organização</span>
          </SC.ContentItem>
          <SC.ContentItem>
            <img src={MindImg} alt="Silhueta cabeça" />
            <span>planos realistas e metas alcançáveis</span>
          </SC.ContentItem>
          <SC.ContentItem>
            <img src={TargetImg} alt="Alvo" />
            <span>como manter o foco e a concentração</span>
          </SC.ContentItem>
          <SC.ContentItem>
            <img src={CoffeeImg} alt="Xícara de café" />
            <span>o poder do cochilo</span>
          </SC.ContentItem>
        </SC.ContentItems>
      </SC.Content>
      <SC.Content
        isFlex
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
      >
        <SC.TargetPublicTextWrapper>
          <SC.ContentTitle>Para quem é esse eBook?</SC.ContentTitle>
          <SC.TargetPublicItems>
            <ul>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>para quem deseja melhorar a qualidade dos estudos</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>
                  para quem estuda, estuda, estuda e tem a sensação de não ter
                  aprendido
                </span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>
                  para quem não consegue dar conta de estudar toda matéria que
                  precisa
                </span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>
                  para quem quer diminuir o tempo dedicado aos estudos sem
                  prejuízos
                </span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>para quem tem a impressão de estar estudando errado</span>
              </li>
            </ul>
          </SC.TargetPublicItems>
        </SC.TargetPublicTextWrapper>
        <SC.TargetPublicImg
          src={PersonHoldinTabletImg}
          alt="Pessoa segurando tablet"
        />
      </SC.Content>
    </Layout>
  )
}

SC.Content = styled(Container)`
  padding-top: 80px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
    padding-top: 40px;
  }
`

SC.ContentTitle = styled.h2`
  color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
  font-size: 32px;
  font-weight: 700;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 26px;
    text-align: center;
  }
`

SC.ContentItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-top: 60px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: 40px;
    width: 100%;
    flex-direction: column;
  }
`

SC.ContentItem = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    height: auto;
    margin-bottom: 35px;
  }

  img {
    width: 50%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      width: 100px;
    }
  }

  span {
    text-align: center;
    display: block;
    width: 80%;
    font-family: ${({ theme }) => theme.fonts.NUNITO};
    font-size: 20px;
    font-weight: 600;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
      font-size: 18px;
      width: 100%;
    }
  }
`

SC.TargetPublicTextWrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 200px;
  padding: 0 80px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: 40px;
    margin-bottom: 240px;
    padding: 0 25px;
  }
`

SC.TargetPublicItems = styled.div`
  ul {
    list-style-type: none;
    margin: 0;
    margin-top: 40px;

    li {
      span {
        font-family: ${({ theme }) => theme.fonts.NUNITO};
        font-size: 22px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
          font-size: 18px;
        }
      }

      svg {
        margin-right: 5px;
        height: 14px;
      }
    }
  }
`

SC.TargetPublicImg = styled.img`
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
`

export default EbookEstudosMaisProdutivos
